/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * www.fastighetsagarna.se
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2018
 */


/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The fonts folder(s) should be placed relative to the regular CSS file.
 *
 * You can use either the complete or subsetted fonts:
 * If you don’t require the entire range of characters, you can use the smaller, subsetted webfonts instead.
 * See "Glyphs & Languages" for an overview of the characters in the corresponding html file in the root directory of this package.
 *
 */


/* Complete */

@font-face {
  font-display: swap;
  font-family: "LL Brown Bold Web";
    src: url(/build/production/static/media/lineto-brown-bold.599a0123.eot);
    src: url(/build/production/static/media/lineto-brown-bold.599a0123.eot?#iefix) format("embedded-opentype"),
         url(/build/production/static/media/lineto-brown-bold.e8c73ac9.woff2) format("woff2"),
         url(/build/production/static/media/lineto-brown-bold.28dff583.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "LL Brown Bold Alternate Web";
    src: url(/build/production/static/media/lineto-brown-boldalt.90dc838c.eot);
    src: url(/build/production/static/media/lineto-brown-boldalt.90dc838c.eot?#iefix) format("embedded-opentype"),
         url(/build/production/static/media/lineto-brown-boldalt.1d823bf3.woff2) format("woff2"),
         url(/build/production/static/media/lineto-brown-boldalt.4475b37e.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "LL Brown Reclining Bold Web";
    src: url(/build/production/static/media/lineto-brown-reclinbold.902d5a4a.eot);
    src: url(/build/production/static/media/lineto-brown-reclinbold.902d5a4a.eot?#iefix) format("embedded-opentype"),
         url(/build/production/static/media/lineto-brown-reclinbold.0349266d.woff2) format("woff2"),
         url(/build/production/static/media/lineto-brown-reclinbold.e8a08c4e.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "LL Brown Reclining Bold Alternate Web";
    src: url(/build/production/static/media/lineto-brown-reclinboldalt.fe9388d4.eot);
    src: url(/build/production/static/media/lineto-brown-reclinboldalt.fe9388d4.eot?#iefix) format("embedded-opentype"),
         url(/build/production/static/media/lineto-brown-reclinboldalt.e002abfb.woff2) format("woff2"),
         url(/build/production/static/media/lineto-brown-reclinboldalt.f8648fa2.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}

/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * www.fastighetsagarna.se
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2018
 */


/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The fonts folder(s) should be placed relative to the regular CSS file.
 *
 * You can use either the complete or subsetted fonts:
 * If you don’t require the entire range of characters, you can use the smaller, subsetted webfonts instead.
 * See "Glyphs & Languages" for an overview of the characters in the corresponding html file in the root directory of this package.
 *
 */


/* Complete */

@font-face {
  font-display: swap;
  font-family: "LL Brown Reclining Regular Web";
    src: url(/build/production/static/media/lineto-brown-reclinregular.7703a819.eot);
    src: url(/build/production/static/media/lineto-brown-reclinregular.7703a819.eot?#iefix) format("embedded-opentype"),
         url(/build/production/static/media/lineto-brown-reclinregular.7bac6498.woff2) format("woff2"),
         url(/build/production/static/media/lineto-brown-reclinregular.26611e22.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "LL Brown Reclining Regular Alternate Web";
    src: url(/build/production/static/media/lineto-brown-reclinregularalt.60389ecd.eot);
    src: url(/build/production/static/media/lineto-brown-reclinregularalt.60389ecd.eot?#iefix) format("embedded-opentype"),
         url(/build/production/static/media/lineto-brown-reclinregularalt.dc03231e.woff2) format("woff2"),
         url(/build/production/static/media/lineto-brown-reclinregularalt.a205c974.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "LL Brown Regular Web";
    src: url(/build/production/static/media/lineto-brown-regular.aa679f9d.eot);
    src: url(/build/production/static/media/lineto-brown-regular.aa679f9d.eot?#iefix) format("embedded-opentype"),
         url(/build/production/static/media/lineto-brown-regular.4cecd478.woff2) format("woff2"),
         url(/build/production/static/media/lineto-brown-regular.f0e1ba11.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "LL Brown Regular Alternate Web";
    src: url(/build/production/static/media/lineto-brown-regularalt.a3e9e780.eot);
    src: url(/build/production/static/media/lineto-brown-regularalt.a3e9e780.eot?#iefix) format("embedded-opentype"),
         url(/build/production/static/media/lineto-brown-regularalt.6738b0a1.woff2) format("woff2"),
         url(/build/production/static/media/lineto-brown-regularalt.3196b524.woff) format("woff");
    font-weight: normal;
    font-style: normal;
}


/*
 * Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and 
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2017 Colophon Foundry
 *
 * Licenced to Gunilla Sandberg FASTIGHETS&Auml;GARNA STOCKHOLM
 */

@font-face {
  font-display: swap;
  font-family: 'apercu-bold-pro-web';
  src: url(/build/production/static/media/apercu-bold-pro.c87b0eab.eot);
  src: url(/build/production/static/media/apercu-bold-pro.c87b0eab.eot?#iefix) format('embedded-opentype'), url(/build/production/static/media/apercu-bold-pro.10672c92.woff) format('woff'),
    url(/build/production/static/media/apercu-bold-pro.0a4fafbc.woff2) format('woff2'), url(/build/production/static/media/apercu-bold-pro.e1535426.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

/*
 * Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and 
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2017 Colophon Foundry
 *
 * Licenced to Gunilla Sandberg FASTIGHETS&Auml;GARNA STOCKHOLM
 */

@font-face {
  font-family: 'apercu-mono-medium-pro';
  font-display: swap;
  src: url(/build/production/static/media/apercu-mono-medium-pro.1edae7a4.eot);
  src: url(/build/production/static/media/apercu-mono-medium-pro.1edae7a4.eot?#iefix) format('embedded-opentype'),
    url(/build/production/static/media/apercu-mono-medium-pro.f320c62b.woff) format('woff'), url(/build/production/static/media/apercu-mono-medium-pro.b5d89c84.woff2) format('woff2'),
    url(/build/production/static/media/apercu-mono-medium-pro.29f56366.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'apercu-regular-pro';
    src: url(/build/production/static/media/apercupro-regular.740487f1.eot);
    src: 
      url(/build/production/static/media/apercupro-regular.5988df6a.woff) format('woff'),
      url(/build/production/static/media/apercupro-regular.b2cc230e.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  

div.slider .slider-frame ul.slider-list {
    min-height: 10rem !important;
    margin: 0 auto !important;
    left: -1.4rem;
}

div.slider .slider-frame {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

div.slider .slider-control-centerleft, div.slider .slider-control-centerright {
  height:100% !important;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
:root {
  --container-width: calc((100vw - 1122px) / 2);
  --black: #2f2f2f;
  --grey--dark: #4a4a4a;
  --grey--medium: #dadada;
  --grey--light: #f7f8f9;
  --primary: #fc8600;
  --primary--darker: #e77900;
  --secondary: #006e86;
  --white: #ffffff;
}
.container--large {
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
  max-width: 1264px;
}

.container--medium {
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
  max-width: 1090px;
}
body {
  scroll-behavior: smooth;
}
.h1,
.h2,
.h3,
.h4,
.tinymce h1,
.tinymce h2,
.tinymce h3,
.tinymce h4 {
  font-family: 'apercu-bold-pro-web', sans-serif;
  position: relative;
  margin: 0;
  color: var(--black);
  letter-spacing: -0.6px;
}

.h1,
.tinymce h1 {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 24px;
  letter-spacing: -0.6px;
}

.h2,
.tinymce h2 {
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 34px;
}

.h3,
.tinymce h3 {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 30px;
}

.h4,
.tinymce h4 {
  margin-bottom: 12px;
  font-size: 20px;
  line-height: 26px;
}

.tinymce,
.p, 
.tinymce li,
.tinymce p {
  font-size: 16px;
  line-height: 22px;
  color: var(--grey--dark);
  font-family: 'LL Brown Regular Web', sans-serif;
}

.p, 
.tinymce li,
.tinymce p {
  margin: 0 0 22px 0;
}


.p--lead {
  font-size: 18px;
  line-height: 24px;
}

.p--quote {
  font-family: 'apercu-bold-pro-web', sans-serif;
  text-align: center;
}

@media (min-width: 768px) {
  .h1,
  .tinymce h1 {
    font-size: 60px;
    line-height: 66px;
  }

  .h2,
  .tinymce h2 {
    font-size: 40px;
    line-height: 46px;
  }

  .h3,
  .tinymce h3 {
    font-size: 32px;
    line-height: 38px;
  }

  .h4,
  .tinymce h4 {
    font-size: 24px;
    line-height: 30px;
  }

  .p--lead {
    font-size: 20px;
    line-height: 26px;
  }
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border: none;
  font-family: 'apercu-bold-pro-web', sans-serif;
  font-size: 16px;
  color: var(--white);
  text-decoration: none;
  cursor: pointer;
}

.button--full-width {
  width: 100%;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
}

.button--primary {
  background-color: var(--primary);
}

.button--primary:hover,
.feature-card:hover .button--primary {
  background-color: var(--primary--darker);
}
.slick-arrow {
  z-index: 999;
}
.slick-prev {
  left: 2%;
  width: 60px;
  height: 100px;
}

.slick-prev:before {
  content: url(/build/production/static/media/left-arrows.b4bc624f.svg);
}

.slick-next:before {
  content: url(/build/production/static/media/right-arrows.761048ab.svg);
}

.slick-next {
  right: 2%;
  width: 60px;
  height: 100px;
}

.slick-dots li button:before {
  font-size: 16px;
}

.slick-track{
  margin-left: auto;
  margin-right: auto;
}

.side-menu{
  list-style: none;
}

.side-menu__heading {
  margin: 0 0 6px;
  font-size: 24px;
  line-height: 1.4;
}

.side-menu__link {
  padding: 21px 0 21px 16px;
  max-width: 11rem;
  background-color: var(--white);
  border-bottom: 1px solid var(--black);
  font-family: "LL Brown Regular Web";
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0px;
}

.side-menu__link:first-child {
  padding: 0 0 0.5rem 0;
  background-color: var(--white) !important;
  border-bottom: 2px solid var(--primary);
  font-family: apercu-bold-pro-web;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
}

.side-menu__link:last-child {
  border-bottom: none;
}


.side-menu__link a,
.side-menu__link a:hover {
  color: var(--black);
  text-decoration: none !important;
}

.side-menu__link--current {
  background-color: var(--grey--medium);
}
.dynamic-feature-block {
  box-sizing: border-box;
  width: 100%;
  padding: 48px 0;
}

.dynamic-feature-block + .dynamic-feature-block  {
  padding-top: 0
}

.dynamic-feature-block a {
  text-decoration: none;
}

.dynamic-feature-block--dark-grey {
  background-color: var(--black);
}

.dynamic-feature-block--medium-grey {
  background-color: var(--grey--medium);
}

.dynamic-feature-block--dark-grey > div:first-of-type > * {
  color: var(--white);
}

.dynamic-feature-block--light-grey {
  background-color: var(--grey--light);
}

.dynamic-feature-block--white {
  background-color: var(--white);
}

.dynamic-feature-block--white .feature-card {
  background-color: var(--grey--light);
}

.dynamic-feature-block__inner-container {
  display: grid;
  grid-gap: 24px;
  gap: 24px;
  padding: 0 16px;
}

.dynamic-feature-block__inner-container--4 {
  grid-template-columns: repeat(4, 1fr);
}

.dynamic-feature-block__inner-container--3 {
  grid-template-columns: repeat(3, 1fr);
}

.dynamic-feature-block__inner-container--2 {
  grid-template-columns: repeat(2, 1fr);
}

.dynamic-feature-block__inner-container--1 {
  grid-template-columns: 1fr;
}

.feature-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--white);
  color: var(--black);
}

.hand {
    cursor: pointer;
} 

.content_container {
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 100%;
}

    .content_container > div:first-child > div:first-child > *:first-child {
        margin-top: 0;
    }

.card-padding--1 {
        padding: 32px;
    }

.card-padding--2 {
    padding: 32px;
}
.card-padding--3 {
    padding: 24px;
}

.card-padding--4 {
    padding: 16px;
}

@media (min-width: 600px) {
  .dynamic-feature-block__inner-container--1 .feature-card {
    display: grid;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto auto 1fr;
  }


    .dynamic-feature-block__inner-container--1 .pictureRightSide {
        grid-template-areas:
            'con img';
         /*   'p img'
            'btn img';*/
    }

    .dynamic-feature-block__inner-container--1  .pictureLeftSide {
        grid-template-areas:
            'img con';
        /*    'img p'
            'img btn';*/
    }

    .dynamic-feature-block__inner-container_only-text--1 .feature-card {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto auto 1fr;
        grid-template-areas:
            'img h'
            'img p'
            'img btn'
    }


    .dynamic-feature-block__inner-container--1 .con {
        display: flex;
        height: 100%;
        grid-area: con;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        padding-left: 16px;
    }

    .dynamic-feature-block__inner-container--1 .feature-card__img {
        margin-bottom: 0;
        height: 100%;
    }

    .dynamic-feature-block__inner-container_only-text--1 .DEJZP {
        max-width: 100%;
        margin-bottom: 0;
    }
}

.feature-card__img {
  object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
/*  margin-bottom: 8px;*/
  grid-area: img;
}

.img-mb--xs {
    margin-bottom: 16px;
}

.img-mb--s {
    margin-bottom: 24px;
}

@media (max-width: 1296px) {
  .dynamic-feature-block__inner-container--4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 650px) {
  .dynamic-feature-block__inner-container--2,
  .dynamic-feature-block__inner-container--3,
  .dynamic-feature-block__inner-container--4 {
    grid-template-columns: 1fr;
  }
}

.feature-card__button {

  flex-shrink: 0;
  /*grid-area: btn;*/
  border: 2px solid var(--primary);
  color: var(--black);
  margin-bottom: 0px;
}

.button-width--xs {
    width: 100%;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
}

.button-width--s {
    min-width: 200px;
    width: 200px;
    max-width: 400px;
}

.feature-card__p {
  grid-area: p;
}
.feature-card__h4 {
  grid-area: h;
}
 
/*ul list needs more padding-left to look good in card*/
.feature-card .jBXSZQ {
    padding-left: 1.6em;
}
/*
.feature-card .bFotDf {
    margin: 0 0 24px 0;
}*/
.feature-card .DEJZP {
    margin: 0 0 24px 0;
}
.side-card-block {
  box-sizing: border-box;
  width: calc((100vw - 1066px) / 2);
  max-width: 18rem;
  position: absolute;
  right: 0px;
  z-index: 1;
}

.side-card-block .feature-side-card {
  background-color: var(--grey--medium);
  box-shadow: none;
  text-decoration: none;
}

@media screen and (max-width: 1550px) {
  .side-card-block {
    translate: none;
    position: relative;
    max-width: none;
    padding: 48px 0;
    width: 100%;
  }
}

@media screen and (min-width: 1550px) {
    .side-card-block .feature-side-card {
        display: flex;
    }

  .side-card-block .feature-side-card__img {
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1550px) {
    .side-card-block .feature-side-card .button {
        margin-left: 0;
        width: auto;
        padding: 0 2rem;
    }
}

.dynamic-side-feature-block {
    box-sizing: border-box;
    width: 100%;
    padding: 48px 0;
}

    .dynamic-side-feature-block + .dynamic-side-feature-block {
        padding-top: 0
    }

    .dynamic-side-feature-block a {
        text-decoration: none;
    }

/*.dynamic-feature-block--dark-grey {
    background-color: var(--black);
}

.dynamic-feature-block--medium-grey {
    background-color: var(--grey--medium);
}

.dynamic-feature-block--dark-grey > div:first-of-type > * {
    color: var(--white);
}

.dynamic-feature-block--light-grey {
    background-color: var(--grey--light);
}

.dynamic-feature-block--white {
    background-color: var(--white);
}

    .dynamic-feature-block--white .feature-card {
        background-color: var(--grey--light);
    }*/

.dynamic-side-feature-block__inner-container {
    display: grid;
    grid-gap: 24px;
    gap: 24px;
    padding: 0 16px;
}

.dynamic-side-feature-block__inner-container--4 {
    grid-template-columns: repeat(4, 1fr);
}

.dynamic-side-feature-block__inner-container--3 {
    grid-template-columns: repeat(3, 1fr);
}

.dynamic-side-feature-block__inner-container--2 {
    grid-template-columns: repeat(2, 1fr);
}

.dynamic-side-feature-block__inner-container--1 {
    grid-template-columns: 1fr;
}

.feature-side-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--white);
    padding: 16px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: var(--black);
}

@media (min-width: 600px) and (max-width: 1550px) {
    .dynamic-side-feature-block__inner-container--1 .feature-side-card {
        display: grid;
        grid-column-gap: 16px;
        -webkit-column-gap: 16px;
                column-gap: 16px;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: auto auto 1fr;
        grid-template-areas:
            'img h'
            'img p'
            'img btn';
    }

    .dynamic-side-feature-block__inner-container--1 .feature-side-card__img {
        margin-bottom: 0;
        height: 100%;
    }
}

.feature-side-card__img {
    object-fit: cover;
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    margin-bottom: 8px;
    grid-area: img;
}

.feature-side-card__button {
    margin-top: auto;
    flex-shrink: 0;
    grid-area: btn;
    color: var(--black);
}

.feature-side-card__p {
    grid-area: p;
}

.feature-side-card__h4 {
    grid-area: h;
}

ul list needs more padding-left to look good in card
.feature-side-card .jBXSZQ {
    padding-left: 3em;
}

.feature-side-card .jBXSZQ {
    padding-left: 1.6em;
}

.quote-block {
  display: flex;
  justify-content: center; 
  box-sizing: border-box;
  width: 100%;
  padding: 96px 0;
  overflow: hidden;
  margin-bottom: 1.667rem;
}

@media screen and (min-width: 768px) {
  .quote-block {
    padding: 48px 0;
  }
}

.quote-block--black {
  background-color: var(--black);
  color: var(--white);
}

.quote-block--medium-grey {
  background-color: var(--grey--medium);
  color: var(--black);
}

.quote-block--light-grey {
  background-color: var(--grey--light);
  color: var(--black);
}

.quote-block--white {
  background-color: var(--white);
  color: var(--black);
  box-shadow: 0px 0px 20px -8px rgba(0,0,0,0.25);
}

.quote-block__quote {
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  margin: 0;
  padding: 0 32px;
  color: inherit !important;
}

.quote-block__quote::before {
  content: var(--data-name);
  display: block;
  position: relative;
  left: 50%;
  translate: -50% 0;
  width: 100px;
  height: 75px;
  margin-bottom: 16px;
  background-image: url('data:image/svg+xml,<svg width="101" height="72" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M55.328 22.004C55.328 7.524 68.29 0 77.333 0s22.907 8.73 22.907 25.017c0 24.87-24.634 45.174-38.801 46.983-1.054-1.809-2.411-4.522-2.411-4.522 6.33-2.563 28.553-16.84 29.457-27.69-4.823 3.015-6.901 4.52-12.66 4.52-12.057 0-20.497-11.152-20.497-22.304Zm-54.568 0C.76 7.524 13.72 0 22.764 0c9.042 0 22.908 8.73 22.908 25.017 0 24.87-24.634 45.174-38.802 46.983-1.054-1.809-2.411-4.522-2.411-4.522 6.331-2.563 28.553-16.84 29.457-27.69-4.823 3.015-6.9 4.52-12.66 4.52C9.2 44.309.76 33.157.76 22.005Z" fill="%23F1841D"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  color: transparent;
}

.quote-block__quote::after {
  content: var(--data-name);
  margin-top: 8px;
  font-family: "LL Brown Regular Web", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: right;
}
/* Config */

/* Layout */

/* Typpography */

/* Components */

/* Blocks */

/* Pre 2023  */
body {
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
} 

* {
  box-sizing: border-box;
}

strong,
bold {
  font-family: "LL Brown Bold Web";
}

/* Older browsers */
html {
  font-size: 16px;
}

/* Modern browsers only need this one */
@media screen and (min-width: 25em) {
  html {
    font-size: calc(18px + (24 - 18) * (100vw - 400px) / (1440 - 400));
  }
}

@media screen and (min-width: 50em) {
  html {
    font-size: calc(16px + (24 - 16) * (100vw - 400px) / (1440 - 400));
  }
}

@media screen and (min-width: 1440px) {
  html {
    font-size: 24px;
  }
}

@media screen and (min-width: 70em) {
  html {
    /* font-size: 30px; */
  }
}

::selection {
  background: rgba(249, 132, 18, 0.5);
  color: white;
}
