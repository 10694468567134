.slick-arrow {
  z-index: 999;
}
.slick-prev {
  left: 2%;
  width: 60px;
  height: 100px;
}

.slick-prev:before {
  content: url(../../static/images/left-arrows.svg);
}

.slick-next:before {
  content: url(../../static/images/right-arrows.svg);
}

.slick-next {
  right: 2%;
  width: 60px;
  height: 100px;
}

.slick-dots li button:before {
  font-size: 16px;
}

.slick-track{
  margin-left: auto;
  margin-right: auto;
}
