.side-card-block {
  box-sizing: border-box;
  width: calc((100vw - 1066px) / 2);
  max-width: 18rem;
  position: absolute;
  right: 0px;
  z-index: 1;
}

.side-card-block .feature-side-card {
  background-color: var(--grey--medium);
  box-shadow: none;
  text-decoration: none;
}

@media screen and (max-width: 1550px) {
  .side-card-block {
    translate: none;
    position: relative;
    max-width: none;
    padding: 48px 0;
    width: 100%;
  }
}

@media screen and (min-width: 1550px) {
    .side-card-block .feature-side-card {
        display: flex;
    }

  .side-card-block .feature-side-card__img {
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1550px) {
    .side-card-block .feature-side-card .button {
        margin-left: 0;
        width: auto;
        padding: 0 2rem;
    }
}

.dynamic-side-feature-block {
    box-sizing: border-box;
    width: 100%;
    padding: 48px 0;
}

    .dynamic-side-feature-block + .dynamic-side-feature-block {
        padding-top: 0
    }

    .dynamic-side-feature-block a {
        text-decoration: none;
    }

/*.dynamic-feature-block--dark-grey {
    background-color: var(--black);
}

.dynamic-feature-block--medium-grey {
    background-color: var(--grey--medium);
}

.dynamic-feature-block--dark-grey > div:first-of-type > * {
    color: var(--white);
}

.dynamic-feature-block--light-grey {
    background-color: var(--grey--light);
}

.dynamic-feature-block--white {
    background-color: var(--white);
}

    .dynamic-feature-block--white .feature-card {
        background-color: var(--grey--light);
    }*/

.dynamic-side-feature-block__inner-container {
    display: grid;
    gap: 24px;
    padding: 0 16px;
}

.dynamic-side-feature-block__inner-container--4 {
    grid-template-columns: repeat(4, 1fr);
}

.dynamic-side-feature-block__inner-container--3 {
    grid-template-columns: repeat(3, 1fr);
}

.dynamic-side-feature-block__inner-container--2 {
    grid-template-columns: repeat(2, 1fr);
}

.dynamic-side-feature-block__inner-container--1 {
    grid-template-columns: 1fr;
}

.feature-side-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--white);
    padding: 16px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: var(--black);
}

@media (min-width: 600px) and (max-width: 1550px) {
    .dynamic-side-feature-block__inner-container--1 .feature-side-card {
        display: grid;
        column-gap: 16px;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: auto auto 1fr;
        grid-template-areas:
            'img h'
            'img p'
            'img btn';
    }

    .dynamic-side-feature-block__inner-container--1 .feature-side-card__img {
        margin-bottom: 0;
        height: 100%;
    }
}

.feature-side-card__img {
    object-fit: cover;
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    margin-bottom: 8px;
    grid-area: img;
}

.feature-side-card__button {
    margin-top: auto;
    flex-shrink: 0;
    grid-area: btn;
    color: var(--black);
}

.feature-side-card__p {
    grid-area: p;
}

.feature-side-card__h4 {
    grid-area: h;
}

ul list needs more padding-left to look good in card
.feature-side-card .jBXSZQ {
    padding-left: 3em;
}

.feature-side-card .jBXSZQ {
    padding-left: 1.6em;
}
