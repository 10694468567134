.h1,
.h2,
.h3,
.h4,
.tinymce h1,
.tinymce h2,
.tinymce h3,
.tinymce h4 {
  font-family: 'apercu-bold-pro-web', sans-serif;
  position: relative;
  margin: 0;
  color: var(--black);
  letter-spacing: -0.6px;
}

.h1,
.tinymce h1 {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 24px;
  letter-spacing: -0.6px;
}

.h2,
.tinymce h2 {
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 34px;
}

.h3,
.tinymce h3 {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 30px;
}

.h4,
.tinymce h4 {
  margin-bottom: 12px;
  font-size: 20px;
  line-height: 26px;
}

.tinymce,
.p, 
.tinymce li,
.tinymce p {
  font-size: 16px;
  line-height: 22px;
  color: var(--grey--dark);
  font-family: 'LL Brown Regular Web', sans-serif;
}

.p, 
.tinymce li,
.tinymce p {
  margin: 0 0 22px 0;
}


.p--lead {
  font-size: 18px;
  line-height: 24px;
}

.p--quote {
  font-family: 'apercu-bold-pro-web', sans-serif;
  text-align: center;
}

@media (min-width: 768px) {
  .h1,
  .tinymce h1 {
    font-size: 60px;
    line-height: 66px;
  }

  .h2,
  .tinymce h2 {
    font-size: 40px;
    line-height: 46px;
  }

  .h3,
  .tinymce h3 {
    font-size: 32px;
    line-height: 38px;
  }

  .h4,
  .tinymce h4 {
    font-size: 24px;
    line-height: 30px;
  }

  .p--lead {
    font-size: 20px;
    line-height: 26px;
  }
}