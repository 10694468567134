/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * www.fastighetsagarna.se
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2018
 */


/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The fonts folder(s) should be placed relative to the regular CSS file.
 *
 * You can use either the complete or subsetted fonts:
 * If you don’t require the entire range of characters, you can use the smaller, subsetted webfonts instead.
 * See "Glyphs & Languages" for an overview of the characters in the corresponding html file in the root directory of this package.
 *
 */


/* Complete */

@font-face {
  font-display: swap;
  font-family: "LL Brown Bold Web";
    src: url("lineto-brown-bold.eot");
    src: url("lineto-brown-bold.eot?#iefix") format("embedded-opentype"),
         url("lineto-brown-bold.woff2") format("woff2"),
         url("lineto-brown-bold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "LL Brown Bold Alternate Web";
    src: url("lineto-brown-boldalt.eot");
    src: url("lineto-brown-boldalt.eot?#iefix") format("embedded-opentype"),
         url("lineto-brown-boldalt.woff2") format("woff2"),
         url("lineto-brown-boldalt.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "LL Brown Reclining Bold Web";
    src: url("lineto-brown-reclinbold.eot");
    src: url("lineto-brown-reclinbold.eot?#iefix") format("embedded-opentype"),
         url("lineto-brown-reclinbold.woff2") format("woff2"),
         url("lineto-brown-reclinbold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "LL Brown Reclining Bold Alternate Web";
    src: url("lineto-brown-reclinboldalt.eot");
    src: url("lineto-brown-reclinboldalt.eot?#iefix") format("embedded-opentype"),
         url("lineto-brown-reclinboldalt.woff2") format("woff2"),
         url("lineto-brown-reclinboldalt.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
