.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border: none;
  font-family: 'apercu-bold-pro-web', sans-serif;
  font-size: 16px;
  color: var(--white);
  text-decoration: none;
  cursor: pointer;
}

.button--full-width {
  width: 100%;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
}

.button--primary {
  background-color: var(--primary);
}

.button--primary:hover,
.feature-card:hover .button--primary {
  background-color: var(--primary--darker);
}