.container--large {
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
  max-width: 1264px;
}

.container--medium {
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
  max-width: 1090px;
}