.side-menu{
  list-style: none;
}

.side-menu__heading {
  margin: 0 0 6px;
  font-size: 24px;
  line-height: 1.4;
}

.side-menu__link {
  padding: 21px 0 21px 16px;
  max-width: 11rem;
  background-color: var(--white);
  border-bottom: 1px solid var(--black);
  font-family: "LL Brown Regular Web";
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0px;
}

.side-menu__link:first-child {
  padding: 0 0 0.5rem 0;
  background-color: var(--white) !important;
  border-bottom: 2px solid var(--primary);
  font-family: apercu-bold-pro-web;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
}

.side-menu__link:last-child {
  border-bottom: none;
}


.side-menu__link a,
.side-menu__link a:hover {
  color: var(--black);
  text-decoration: none !important;
}

.side-menu__link--current {
  background-color: var(--grey--medium);
}