.quote-block {
  display: flex;
  justify-content: center; 
  box-sizing: border-box;
  width: 100%;
  padding: 96px 0;
  overflow: hidden;
  margin-bottom: 1.667rem;
}

@media screen and (min-width: 768px) {
  .quote-block {
    padding: 48px 0;
  }
}

.quote-block--black {
  background-color: var(--black);
  color: var(--white);
}

.quote-block--medium-grey {
  background-color: var(--grey--medium);
  color: var(--black);
}

.quote-block--light-grey {
  background-color: var(--grey--light);
  color: var(--black);
}

.quote-block--white {
  background-color: var(--white);
  color: var(--black);
  box-shadow: 0px 0px 20px -8px rgba(0,0,0,0.25);
}

.quote-block__quote {
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  margin: 0;
  padding: 0 32px;
  color: inherit !important;
}

.quote-block__quote::before {
  content: var(--data-name);
  display: block;
  position: relative;
  left: 50%;
  translate: -50% 0;
  width: 100px;
  height: 75px;
  margin-bottom: 16px;
  background-image: url('data:image/svg+xml,<svg width="101" height="72" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M55.328 22.004C55.328 7.524 68.29 0 77.333 0s22.907 8.73 22.907 25.017c0 24.87-24.634 45.174-38.801 46.983-1.054-1.809-2.411-4.522-2.411-4.522 6.33-2.563 28.553-16.84 29.457-27.69-4.823 3.015-6.901 4.52-12.66 4.52-12.057 0-20.497-11.152-20.497-22.304Zm-54.568 0C.76 7.524 13.72 0 22.764 0c9.042 0 22.908 8.73 22.908 25.017 0 24.87-24.634 45.174-38.802 46.983-1.054-1.809-2.411-4.522-2.411-4.522 6.331-2.563 28.553-16.84 29.457-27.69-4.823 3.015-6.9 4.52-12.66 4.52C9.2 44.309.76 33.157.76 22.005Z" fill="%23F1841D"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  color: transparent;
}

.quote-block__quote::after {
  content: var(--data-name);
  margin-top: 8px;
  font-family: "LL Brown Regular Web", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: right;
}