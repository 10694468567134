/* Config */
@import './css/config/colors.css';

/* Layout */
@import './css/layout/containers.css';
@import './css/layout/body.css';

/* Typpography */
@import './css/typography/fonts.css';

/* Components */
@import './css/components/buttons.css';
@import './css/components/slick.css';
@import './css/components/side-menu.css';

/* Blocks */
@import './blocks/dynamicfeatureblock/style.css';
@import './blocks/sidecardblock/style.css';
@import './blocks/quoteblock/style.css';

/* Pre 2023  */
body {
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
} 

* {
  box-sizing: border-box;
}

strong,
bold {
  font-family: "LL Brown Bold Web";
}

/* Older browsers */
html {
  font-size: 16px;
}

/* Modern browsers only need this one */
@media screen and (min-width: 25em) {
  html {
    font-size: calc(18px + (24 - 18) * (100vw - 400px) / (1440 - 400));
  }
}

@media screen and (min-width: 50em) {
  html {
    font-size: calc(16px + (24 - 16) * (100vw - 400px) / (1440 - 400));
  }
}

@media screen and (min-width: 1440px) {
  html {
    font-size: 24px;
  }
}

@media screen and (min-width: 70em) {
  html {
    /* font-size: 30px; */
  }
}

::selection {
  background: rgba(249, 132, 18, 0.5);
  color: white;
}