:root {
  --container-width: calc((100vw - 1122px) / 2);
  --black: #2f2f2f;
  --grey--dark: #4a4a4a;
  --grey--medium: #dadada;
  --grey--light: #f7f8f9;
  --primary: #fc8600;
  --primary--darker: #e77900;
  --secondary: #006e86;
  --white: #ffffff;
}