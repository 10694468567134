/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * www.fastighetsagarna.se
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2018
 */


/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The fonts folder(s) should be placed relative to the regular CSS file.
 *
 * You can use either the complete or subsetted fonts:
 * If you don’t require the entire range of characters, you can use the smaller, subsetted webfonts instead.
 * See "Glyphs & Languages" for an overview of the characters in the corresponding html file in the root directory of this package.
 *
 */


/* Complete */

@font-face {
  font-display: swap;
  font-family: "LL Brown Reclining Regular Web";
    src: url("lineto-brown-reclinregular.eot");
    src: url("lineto-brown-reclinregular.eot?#iefix") format("embedded-opentype"),
         url("lineto-brown-reclinregular.woff2") format("woff2"),
         url("lineto-brown-reclinregular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "LL Brown Reclining Regular Alternate Web";
    src: url("lineto-brown-reclinregularalt.eot");
    src: url("lineto-brown-reclinregularalt.eot?#iefix") format("embedded-opentype"),
         url("lineto-brown-reclinregularalt.woff2") format("woff2"),
         url("lineto-brown-reclinregularalt.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "LL Brown Regular Web";
    src: url("lineto-brown-regular.eot");
    src: url("lineto-brown-regular.eot?#iefix") format("embedded-opentype"),
         url("lineto-brown-regular.woff2") format("woff2"),
         url("lineto-brown-regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "LL Brown Regular Alternate Web";
    src: url("lineto-brown-regularalt.eot");
    src: url("lineto-brown-regularalt.eot?#iefix") format("embedded-opentype"),
         url("lineto-brown-regularalt.woff2") format("woff2"),
         url("lineto-brown-regularalt.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

