.dynamic-feature-block {
  box-sizing: border-box;
  width: 100%;
  padding: 48px 0;
}

.dynamic-feature-block + .dynamic-feature-block  {
  padding-top: 0
}

.dynamic-feature-block a {
  text-decoration: none;
}

.dynamic-feature-block--dark-grey {
  background-color: var(--black);
}

.dynamic-feature-block--medium-grey {
  background-color: var(--grey--medium);
}

.dynamic-feature-block--dark-grey > div:first-of-type > * {
  color: var(--white);
}

.dynamic-feature-block--light-grey {
  background-color: var(--grey--light);
}

.dynamic-feature-block--white {
  background-color: var(--white);
}

.dynamic-feature-block--white .feature-card {
  background-color: var(--grey--light);
}

.dynamic-feature-block__inner-container {
  display: grid;
  gap: 24px;
  padding: 0 16px;
}

.dynamic-feature-block__inner-container--4 {
  grid-template-columns: repeat(4, 1fr);
}

.dynamic-feature-block__inner-container--3 {
  grid-template-columns: repeat(3, 1fr);
}

.dynamic-feature-block__inner-container--2 {
  grid-template-columns: repeat(2, 1fr);
}

.dynamic-feature-block__inner-container--1 {
  grid-template-columns: 1fr;
}

.feature-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--white);
  color: var(--black);
}

.hand {
    cursor: pointer;
} 

.content_container {
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 100%;
}

    .content_container > div:first-child > div:first-child > *:first-child {
        margin-top: 0;
    }

.card-padding--1 {
        padding: 32px;
    }

.card-padding--2 {
    padding: 32px;
}
.card-padding--3 {
    padding: 24px;
}

.card-padding--4 {
    padding: 16px;
}

@media (min-width: 600px) {
  .dynamic-feature-block__inner-container--1 .feature-card {
    display: grid;
    column-gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto auto 1fr;
  }


    .dynamic-feature-block__inner-container--1 .pictureRightSide {
        grid-template-areas:
            'con img';
         /*   'p img'
            'btn img';*/
    }

    .dynamic-feature-block__inner-container--1  .pictureLeftSide {
        grid-template-areas:
            'img con';
        /*    'img p'
            'img btn';*/
    }

    .dynamic-feature-block__inner-container_only-text--1 .feature-card {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto auto 1fr;
        grid-template-areas:
            'img h'
            'img p'
            'img btn'
    }


    .dynamic-feature-block__inner-container--1 .con {
        display: flex;
        height: 100%;
        grid-area: con;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        padding-left: 16px;
    }

    .dynamic-feature-block__inner-container--1 .feature-card__img {
        margin-bottom: 0;
        height: 100%;
    }

    .dynamic-feature-block__inner-container_only-text--1 .DEJZP {
        max-width: 100%;
        margin-bottom: 0;
    }
}

.feature-card__img {
  object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
/*  margin-bottom: 8px;*/
  grid-area: img;
}

.img-mb--xs {
    margin-bottom: 16px;
}

.img-mb--s {
    margin-bottom: 24px;
}

@media (max-width: 1296px) {
  .dynamic-feature-block__inner-container--4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 650px) {
  .dynamic-feature-block__inner-container--2,
  .dynamic-feature-block__inner-container--3,
  .dynamic-feature-block__inner-container--4 {
    grid-template-columns: 1fr;
  }
}

.feature-card__button {

  flex-shrink: 0;
  /*grid-area: btn;*/
  border: 2px solid var(--primary);
  color: var(--black);
  margin-bottom: 0px;
}

.button-width--xs {
    width: 100%;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
}

.button-width--s {
    min-width: 200px;
    width: 200px;
    max-width: 400px;
}

.feature-card__p {
  grid-area: p;
}
.feature-card__h4 {
  grid-area: h;
}
 
/*ul list needs more padding-left to look good in card*/
.feature-card .jBXSZQ {
    padding-left: 1.6em;
}
/*
.feature-card .bFotDf {
    margin: 0 0 24px 0;
}*/
.feature-card .DEJZP {
    margin: 0 0 24px 0;
}