
div.slider .slider-frame ul.slider-list {
    min-height: 10rem !important;
    margin: 0 auto !important;
    left: -1.4rem;
}

div.slider .slider-frame {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

div.slider .slider-control-centerleft, div.slider .slider-control-centerright {
  height:100% !important;
}
