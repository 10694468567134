@font-face {
  font-display: swap;
  font-family: 'apercu-regular-pro';
    src: url('apercupro-regular.eot');
    src: 
      url('apercupro-regular.woff') format('woff'),
      url('apercupro-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  