/*
 * Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and 
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2017 Colophon Foundry
 *
 * Licenced to Gunilla Sandberg FASTIGHETS&Auml;GARNA STOCKHOLM
 */

@font-face {
  font-display: swap;
  font-family: 'apercu-bold-pro-web';
  src: url('apercu-bold-pro.eot');
  src: url('apercu-bold-pro.eot?#iefix') format('embedded-opentype'), url('apercu-bold-pro.woff') format('woff'),
    url('apercu-bold-pro.woff2') format('woff2'), url('apercu-bold-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
